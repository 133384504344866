import { AlertColor, Typography, useTheme } from '@mui/material';
import React from 'react';
import ErrorExclamationFilledIcon from '../Icons/ErrorExclamationFilledIcon';
import SuccessTickIcon from '../Icons/SuccessTickIcon';
import { AlertContainer, AlertMessage } from './AlertStyle';

interface AlertProps {
  alertType: AlertColor;
  message: string;
  fontSize?: string;
  fontColor?: string;
}

function Alert({ alertType, message, fontColor, fontSize }: AlertProps) {
  const theme = useTheme();
  const colors = {
    error: { font: theme.palette.primary.main, bg: 'rgba(230, 0, 0, 0.1)' },
    success: { font: theme.palette.primary.main, bg: 'rgb(99,159,143)' }, // placeholder
    info: { font: theme.palette.primary.main, bg: 'rgba(242, 242, 242, 1)' }, // placeholder
    warning: { font: theme.palette.primary.main, bg: 'rgba(255,204,84,0.1)' }, // placeholder
  };

  const icon = {
    error: <ErrorExclamationFilledIcon />,
    success: <SuccessTickIcon />,
    info: null,
    warning: <SuccessTickIcon />, // placeholder
  };
  return (
    <AlertContainer colors={colors[alertType]}>
      <AlertMessage>
        {icon[alertType] ? icon[alertType] : null}{' '}
        <Typography sx={{ fontSize, color: fontColor }}>{message}</Typography>
      </AlertMessage>
    </AlertContainer>
  );
}

export default Alert;
