interface Locales {
  [key: string]: string;
}

interface LocaleDateFormat {
  format: Locales;
}

export const LocaleDateTimeFormatConfig: LocaleDateFormat = {
  format: {
    en: 'dd/LL/y - HH:mm:ss',
  },
};

export const LocaleDateFormatConfig: LocaleDateFormat = {
  format: {
    en: 'dd/LL/y',
  },
};

export const TerminateConnectionDateFormat = 'y-LL-dd';
