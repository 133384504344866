import { Box } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import TextInput from '../../../../components/TextInput/TextInput';
import { EmailRegex, NameRegex } from '../../../../configs/RegexConfig';
import { CreateUserRequest, UserExistsResponse } from '../../../../models/UsersModels';
import {
  updateNewUser,
  updateRequest,
  updateSelectedDropdownUser,
  updateUserAlreadyExists,
} from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';
import useNewConnectionCreateUserStep from './useNewConnectionCreateUserStep';

interface NewConnectionCreateUserStepProps {
  nextStep: (next?: number) => void;
  onClose: () => void;
  onBanSelected: (ban: string) => void;
}
function NewConnectionCreateUserStep({ nextStep, onClose, onBanSelected }: NewConnectionCreateUserStepProps) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, userExists } = useNewConnectionCreateUserStep();

  const newUser = useSelector((state: RootState) => state.newConnectionReducer.newUser);

  /**
   * Navigates to the according next step
   */
  const navigateNextStep = (navigateToExistingUserStep: boolean) => {
    if (navigateToExistingUserStep) {
      nextStep(3);
    } else {
      nextStep(5);
    }
  };

  /**
   * On user exists handler
   * @param response
   * @param values
   */
  const userExistsHandler = async (response: UserExistsResponse, values: CreateUserRequest) => {
    dispatch(updateNewUser(values));
    if (response.exists) {
      dispatch(updateUserAlreadyExists(true));
      dispatch(updateRequest({ userId: response.user.id }));
      dispatch(
        updateSelectedDropdownUser({
          key: response.user.id,
          label: `${response.user.firstName} ${response.user.lastName}`,
          value: response.user.id,
          secondaryLabel: response.user.email,
          data: response.user,
        }),
      );
    }
    await onBanSelected(values.billingAccountBan);
  };

  const formik = useFormik({
    initialValues: {
      firstName: newUser?.firstName || '',
      lastName: newUser?.lastName || '',
      email: newUser?.email || '',
      billingAccountBan: newUser?.billingAccountBan || '',
      username: newUser?.username || '',
      secondaryEmail: newUser?.secondaryEmail || '',
      companyRole: newUser?.companyRole || '',
      department: newUser?.department || '',
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(
          NameRegex,
          t('common.inputValidations.validField', {
            field: t(`pages.eSimManager.newConnectionDrawer.firstName`).toLowerCase(),
          }),
        ),
      lastName: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(
          NameRegex,
          t('common.inputValidations.validField', {
            field: t(`pages.eSimManager.newConnectionDrawer.lastName`).toLowerCase(),
          }),
        ),
      email: yup
        .string()
        .matches(EmailRegex, t('common.inputValidations.email'))
        .required(t('common.inputValidations.requiredField')),
      billingAccountBan: yup.string().required(t('common.inputValidations.requiredField')),
      username: yup.string(),
      secondaryEmail: yup.string().matches(EmailRegex, t('common.inputValidations.email')),
      companyRole: yup.string(),
      department: yup.string(),
    }),
    onSubmit: (values) => {
      userExists({ email: values.email, username: values.username }, (response) => {
        userExistsHandler(response, values).then(() => navigateNextStep(response.exists));
      });
    },
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <form onSubmit={formik.handleSubmit} style={{ paddingTop: '1.5rem' }}>
          <TextInput
            autoComplete="off"
            key="firstName"
            id="firstName"
            name="firstName"
            type="text"
            label={t(`pages.eSimManager.newConnectionDrawer.firstName`)}
            value={formik.values.firstName}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            feedback={formik.touched.firstName && Boolean(formik.errors.firstName) ? formik.errors.firstName : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.firstName`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="lastName"
            id="lastName"
            name="lastName"
            type="text"
            label={t(`pages.eSimManager.newConnectionDrawer.lastName`)}
            value={formik.values.lastName}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            feedback={formik.touched.lastName && Boolean(formik.errors.lastName) ? formik.errors.lastName : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.lastName`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="username"
            id="username"
            name="username"
            type="text"
            label={t(`pages.eSimManager.newConnectionDrawer.username`)}
            value={formik.values.username}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            feedback={formik.touched.username && Boolean(formik.errors.username) ? formik.errors.username : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.username`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="email"
            id="email"
            name="email"
            type="email"
            label={t(`pages.eSimManager.newConnectionDrawer.email`)}
            value={formik.values.email}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            feedback={formik.touched.email && Boolean(formik.errors.email) ? formik.errors.email : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') + t(`pages.eSimManager.newConnectionDrawer.email`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="secondaryEmail"
            id="secondaryEmail"
            name="secondaryEmail"
            type="email"
            label={t(`pages.eSimManager.newConnectionDrawer.secondaryEmail`)}
            value={formik.values.secondaryEmail}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.secondaryEmail && Boolean(formik.errors.secondaryEmail)}
            feedback={
              formik.touched.secondaryEmail && Boolean(formik.errors.secondaryEmail) ? formik.errors.secondaryEmail : ''
            }
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.secondaryEmail`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="billingAccountBan"
            id="billingAccountBan"
            name="billingAccountBan"
            type="text"
            label={t(`pages.eSimManager.newConnectionDrawer.billingAccountBan`)}
            value={formik.values.billingAccountBan}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingAccountBan && Boolean(formik.errors.billingAccountBan)}
            feedback={
              formik.touched.billingAccountBan && Boolean(formik.errors.billingAccountBan)
                ? formik.errors.billingAccountBan
                : ''
            }
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.billingAccountBan`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="companyRole"
            id="companyRole"
            name="companyRole"
            type="text"
            label={t(`pages.eSimManager.newConnectionDrawer.companyRole`)}
            value={formik.values.companyRole}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.companyRole && Boolean(formik.errors.companyRole)}
            feedback={formik.touched.companyRole && Boolean(formik.errors.companyRole) ? formik.errors.companyRole : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.companyRole`).toLowerCase()
            }
          />
          <TextInput
            autoComplete="off"
            key="department"
            id="department"
            name="department"
            type="text"
            label={t(`pages.eSimManager.newConnectionDrawer.department`)}
            value={formik.values.department}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.department && Boolean(formik.errors.department)}
            feedback={formik.touched.department && Boolean(formik.errors.department) ? formik.errors.department : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={
              t('common.placeholders.inputPlaceholder') +
              t(`pages.eSimManager.newConnectionDrawer.department`).toLowerCase()
            }
          />
        </form>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} disabled={isLoading} />
          <Button
            label={t('common.continue')}
            color="primaryButton"
            disabled={!formik.isValid || isLoading}
            type="submit"
            onClick={formik.submitForm}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default NewConnectionCreateUserStep;
