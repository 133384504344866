import { useTranslation } from 'react-i18next';
import {
  DeviceJourney,
  LiveNotificationResponse,
  NotificationCounter,
  NotificationType,
} from '../../models/NotificationsModels';

function useLiveNotificationsUtils() {
  const [t] = useTranslation();

  const helpUrls = {
    [DeviceJourney.NEW_CONNECTION_PULL]: '/help/Content/Topics/eSIM/eSIM%20Manager/esim_new_connection_request.htm',
    [DeviceJourney.NEW_CONNECTION_PUSH]: '/help/Content/Topics/eSIM/eSIM%20Manager/esim_new_connection_request.htm',
    [DeviceJourney.UPGRADE_PULL]: '/help/Content/Topics/eSIM/eSIM%20Manager/esim_SIM_swap.htm',
    [DeviceJourney.UPGRADE_PUSH]: '/help/Content/Topics/eSIM/eSIM%20Manager/esim_SIM_swap.htm',
    [DeviceJourney.LOST_STOLEN_RESTORE]: '/help/Default_CSH.htm',
    [DeviceJourney.LOST_STOLEN_SUSPEND]: '/help/Default_CSH.htm',
    [DeviceJourney.TERMINATION_TERMINATION]: '/help/Default_CSH.htm',
  };

  /**
   * Returns the title for the side toast liveNotification
   * @param liveNotification
   */
  const liveNotificationTitleAdapter = (liveNotification: LiveNotificationResponse) =>
    t(`common.liveNotifications.titles.${liveNotification.type}`);

  /**
   * Checks if it's a device journey notification
   * @param notificationType
   */
  const isDeviceJourney = (notificationType: NotificationType): notificationType is DeviceJourney =>
    Object.values(DeviceJourney).includes(notificationType as DeviceJourney);

  /**
   * Checks if it's a notification counter notification
   * @param notificationType
   */
  const isNotificationCounter = (notificationType: NotificationType): notificationType is NotificationCounter =>
    Object.values(NotificationCounter).includes(notificationType as NotificationCounter);

  return { isNotificationCounter, isDeviceJourney, liveNotificationTitleAdapter, helpUrls };
}

export default useLiveNotificationsUtils;
