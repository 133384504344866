import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownItem } from '../components/Dropdown/DropdownModels';
import { NotificationSetting } from '../models/CompanyOnboardingModels';
import { Device, NewConnectionRequest } from '../models/UserDevicesModels';
import { CreateUserRequest } from '../models/UsersModels';

type UserSelectionType = '' | 'CREATE' | 'USE_EXISTING';
type TypeOfConnection = '' | 'ESIM' | 'DEVICE';

interface NewConnectionStateData {
  request: NewConnectionRequest;
  selectedDropdownUser: DropdownItem;
  typeOfConnection: TypeOfConnection;
  device: Device | null;
  newUser: CreateUserRequest | null;
  userSelectionType: UserSelectionType;
  userAlreadyExists: boolean;
  qrCodeNotificationSettings: NotificationSetting[];
  hasDistributionList: boolean;
}

const initialState: NewConnectionStateData = {
  request: {
    notificationType: '',
    userId: '',
    eid: '',
    notifyAdmin: true,
    notifyDistributionList: false,
    qrCodeNotification: 'ADMIN',
    tariffCode: '',
  },
  selectedDropdownUser: { key: '', label: '', value: '', secondaryLabel: '' },
  newUser: null,
  typeOfConnection: '',
  device: null,
  userSelectionType: '',
  userAlreadyExists: false,
  qrCodeNotificationSettings: [],
  hasDistributionList: false,
};

export const NewConnectionSlide = createSlice({
  name: 'newConnection',
  initialState,
  reducers: {
    reset: () => initialState,
    setDevice: (state, action: PayloadAction<Device>) => {
      state.device = action.payload;
    },
    updateSelectedDropdownUser: (state, action: PayloadAction<DropdownItem>) => {
      state.selectedDropdownUser = action.payload;
    },
    updateTypeOfConnection: (state, action: PayloadAction<'' | 'ESIM' | 'DEVICE'>) => {
      state.request.eid = '';
      state.typeOfConnection = action.payload;
    },
    updateRequest: (state, action: PayloadAction<Partial<NewConnectionRequest>>) => {
      state.request = {
        ...state.request,
        ...action.payload,
      };
    },
    resetEid: (state) => {
      state.request.eid = '';
    },
    updateUserSelectionType: (state, action: PayloadAction<'' | 'CREATE' | 'USE_EXISTING'>) => {
      state.userSelectionType = action.payload;
      state.typeOfConnection = initialState.typeOfConnection;
      state.request = initialState.request;
      state.userAlreadyExists = initialState.userAlreadyExists;
      state.selectedDropdownUser = initialState.selectedDropdownUser;
      state.qrCodeNotificationSettings = initialState.qrCodeNotificationSettings;
      if (action.payload === 'USE_EXISTING') {
        state.newUser = initialState.newUser;
      } else {
        state.selectedDropdownUser = initialState.selectedDropdownUser;
      }
    },
    updateNewUser: (state, action: PayloadAction<CreateUserRequest>) => {
      state.newUser = action.payload;
    },
    updateUserAlreadyExists: (state, action: PayloadAction<boolean>) => {
      state.userAlreadyExists = action.payload;
    },
    setNotificationSettings: (state, action: PayloadAction<NotificationSetting[]>) => {
      state.hasDistributionList = action.payload.some((setting) => setting.type === 'DISTRIBUTION_LIST');
      if (action.payload.some((setting) => setting.type === 'END_USER')) state.request.qrCodeNotification = 'END_USER';
      state.qrCodeNotificationSettings = action.payload;
    },
  },
});

export const {
  reset,
  updateRequest,
  updateSelectedDropdownUser,
  updateTypeOfConnection,
  setDevice,
  resetEid,
  updateUserSelectionType,
  updateNewUser,
  updateUserAlreadyExists,
  setNotificationSettings,
} = NewConnectionSlide.actions;

export default NewConnectionSlide.reducer;
