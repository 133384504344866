import { LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const LinkWrapper = styled(RouterLink)<LinkProps>(({ theme }) => ({
  fontSize: '1.125rem',
  fontStyle: 'normal',
  lineHeight: '1.625rem',
  color: theme.palette.text.primary,
  textDecorationColor: theme.palette.text.primary,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.state.linkHover,
    textDecorationColor: theme.palette.state.linkHover,
  },
  '&.active': {
    color: theme.palette.primary.main,
  },
}));

export interface ClickableLinkProps extends PropsWithChildren, LinkProps {
  to: string;
}

function ClickableLink({ children, to, ...rest }: ClickableLinkProps) {
  return (
    <LinkWrapper to={to} sx={rest.sx} className={rest.className} onClick={rest.onClick} target={rest.target}>
      {children}
    </LinkWrapper>
  );
}

export default ClickableLink;
