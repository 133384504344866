import * as React from 'react';
import { SVGProps, memo } from 'react';

function ErrorExclamationFilledIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path fill="#E60000" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M12 6.975c.266 0 .485.198.52.454l.005.071v6.236a.525.525 0 0 1-1.045.071l-.005-.071V7.5c0-.29.235-.525.525-.525Zm0 8.775a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
const Memo = memo(ErrorExclamationFilledIcon);
export default Memo;
