import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  DeviceDetailsResponse,
  DeviceUpdateRequest,
  NewConnectionRequest,
  NewConnectionResponse,
  ResendNotificationRequest,
  ResendNotificationResponse,
  RestoreConnectionRequest,
  RestoreConnectionResponse,
  SuspendSimRequest,
  TerminateConnectionRequest,
  TerminateConnectionResponse,
  UpgradeDeviceRequest,
  UpgradeDeviceResponse,
  UserDevicesResponse,
} from '../../models/UserDevicesModels';
import {
  CreateUserRequest,
  CreateUserResponse,
  DeviceByEidRequest,
  DeviceByEidResponse,
  UserExistsRequest,
  UserExistsResponse,
  UserRequest,
  UserResponse,
  UsersListRequest,
  UsersListResponse,
} from '../../models/UsersModels';

const UsersService = {
  api: {
    fetchUsers: (params: UsersListRequest): AxiosPromise<UsersListResponse> => Api.post(`/users/list`, params),
    fetchUser: (params: UserRequest): AxiosPromise<UserResponse> => Api.post(`/users/user`, params),
    userExists: (params: UserExistsRequest): AxiosPromise<UserExistsResponse> => Api.post(`/users/exists`, params),
    createUser: (params: CreateUserRequest): AxiosPromise<CreateUserResponse> => Api.post(`/users/create`, params),
    fetchDeviceByEid: (params: DeviceByEidRequest): AxiosPromise<DeviceByEidResponse> =>
      Api.post(`/devices/eid`, params),
    fetchDeviceById: (id: string): AxiosPromise<DeviceDetailsResponse> => Api.get(`/devices/id/${id}`),
    updateDevice: (params: DeviceUpdateRequest): AxiosPromise<DeviceDetailsResponse> =>
      Api.post(`/devices/update`, params),
    fetchUserDevices: (
      id: string,
      params: { status: 'ALL' | 'FREE' | 'IN_USE'; deviceOnly: boolean },
    ): AxiosPromise<UserDevicesResponse> => Api.get(`/devices/user/${id}`, { params }),
    fetchFreeUserDevices: (id: string): AxiosPromise<UserDevicesResponse> => Api.get(`/devices/user/${id}/free`),
    restoreConnection: (params: RestoreConnectionRequest): AxiosPromise<RestoreConnectionResponse> =>
      Api.post(`/journey/restore`, params),
    suspendSim: (params: SuspendSimRequest): AxiosPromise<UpgradeDeviceResponse> =>
      Api.post(`/journey/suspend`, params),
    upgradeUserDevice: (params: UpgradeDeviceRequest): AxiosPromise<UpgradeDeviceResponse> =>
      Api.post(`/journey/update`, params),
    newConnection: (params: NewConnectionRequest): AxiosPromise<NewConnectionResponse> =>
      Api.post(`/journey/new`, params),
    resendNotification: (params: ResendNotificationRequest): AxiosPromise<ResendNotificationResponse> =>
      Api.post(`/journey/resend`, params),
    terminateConnection: (params: TerminateConnectionRequest): AxiosPromise<TerminateConnectionResponse> =>
      Api.post(`/journey/termination`, params),
  },
};

export default UsersService;
