import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../../components/Drawer/Drawer';
import { GetDeviceByIdFn, GetDeviceFn, Sim } from '../../../../../models/UserDevicesModels';
import SuspendSimSummaryStep from './SuspendSimSummaryStep';
import useSuspendSim from './useSuspendSim';

interface SuspendSimDrawerProps {
  open: boolean;
  onClose: () => void;
  onSuspendSimSuccess: () => void;
  sim: Sim;
  getDeviceById: GetDeviceFn | GetDeviceByIdFn;
}
function SuspendSimDrawer({ open, onClose, onSuspendSimSuccess, sim, getDeviceById }: SuspendSimDrawerProps) {
  /**
   * Handle the close action
   */
  const handleClose = () => {
    onClose();
  };
  const [t] = useTranslation();
  const device = getDeviceById(sim.deviceId);
  const { suspendSim, isLoading, isLoadingNotifications, hasDL } = useSuspendSim(
    handleClose,
    onSuspendSimSuccess,
    sim.simId,
    device,
  );
  const [progress, setProgress] = useState(75);
  const [step, setStep] = useState(1);
  const NUMBER_OF_STEPS = 1;

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === 1) {
      handleClose();
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of the suspend SIM journey
   */
  const renderSteps = () => (
    <SuspendSimSummaryStep
      sim={sim}
      onClose={handleClose}
      nextStep={suspendSim}
      isLoading={isLoading}
      device={device}
      hasDL={hasDL}
      isLoadingNotifications={isLoadingNotifications}
    />
  );

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={t('pages.userDetails.suspendSimDrawer.title')}
      progress={progress}
    >
      <Box sx={{ paddingTop: '2rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default SuspendSimDrawer;
