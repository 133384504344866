import { Box, Grid, Typography, useTheme } from '@mui/material';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import ClickableLinkWithIcon from '../../components/Text/ClickableLinkWithIcon';
import PageTitle from '../../components/Text/PageTitle';
import useDate from '../../hooks/useDate/useDate';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { UploadFileNames, UploadType } from '../../models/ImportModels';
import ImportService from '../../services/Import/ImportService';
import ImportDrawer from './components/ImportDrawer';

function Import() {
  const [t] = useTranslation();
  const theme = useTheme();
  const snackBar = useSnackBar();
  const { localeDateTimeFormat } = useDate();
  const [openDrawer, setOpenDrawer] = useState(false);

  /**
   * Fetches a template file
   * @param type
   */
  const fetchTemplateFile = (type: UploadType) => {
    ImportService.api.fetchFile(UploadFileNames[type]).then(
      (res) => {
        fileDownload(res.data, `${UploadFileNames[type]}_${DateTime.now().toFormat(localeDateTimeFormat)}.csv`);
      },
      () => {
        snackBar.showSnackBar(t('pages.import.downloadError'), 'error');
      },
    );
  };

  return (
    <Grid container sx={{ paddingBottom: '2rem' }}>
      <Grid item xl={4} md={6} xs={12}>
        <PageTitle>{t('pages.import.title')}</PageTitle>
        <Typography variant="body1" paddingTop="1.5rem" lineHeight="normal">
          {t('pages.import.templateInstructions')}
        </Typography>
        <Box sx={{ padding: '1.5rem 0', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <ClickableLinkWithIcon
            onClick={() => {
              fetchTemplateFile(UploadType.USERS);
            }}
            leftIcon={<DownloadIcon fill={theme.palette.state.linkHover} />}
          >
            {t('pages.import.usersTemplate')}
          </ClickableLinkWithIcon>
          <ClickableLinkWithIcon
            onClick={() => {
              fetchTemplateFile(UploadType.DEVICES);
            }}
            leftIcon={<DownloadIcon fill={theme.palette.state.linkHover} />}
          >
            {t('pages.import.devicesTemplate')}
          </ClickableLinkWithIcon>

          <ClickableLinkWithIcon
            onClick={() => {
              fetchTemplateFile(UploadType.NEW_CONNECTIONS);
            }}
            leftIcon={<DownloadIcon fill={theme.palette.state.linkHover} />}
          >
            {t('pages.import.newConnectionsTemplate')}
          </ClickableLinkWithIcon>
        </Box>
        <Typography variant="body1" lineHeight="normal">
          {t('pages.import.uploadInstructions')}
        </Typography>
        <Button
          color="primaryButton"
          sx={{ marginTop: '2.5rem' }}
          label={t('pages.import.import')}
          onClick={() => {
            setOpenDrawer(true);
          }}
        />
        {openDrawer ? (
          <ImportDrawer
            onClose={() => {
              setOpenDrawer(false);
            }}
            open={openDrawer}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

export default Import;
