import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useLiveNotificationsUtils from '../../../contexts/LiveNotificationsContext/useLiveNotificationsUtils';
import useDate from '../../../hooks/useDate/useDate';
import { DeviceJourney, DeviceJourneyData, LiveNotificationResponse } from '../../../models/NotificationsModels';
import { toggleDrawer } from '../../../stores/NotificationsCenterStore';
import ClickableLink from '../../Text/ClickableLink';
import {
  NotificationBody,
  NotificationContainer,
  NotificationDate,
  NotificationTitle,
  UnreadNotification,
} from './NotificationStyles';

interface NotificationProps {
  notification: LiveNotificationResponse;
  markAsRead: (id: string) => void;
}
function Notification({ notification, markAsRead }: NotificationProps) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { timeAgo } = useDate();
  const { isNotificationCounter, isDeviceJourney, liveNotificationTitleAdapter, helpUrls } =
    useLiveNotificationsUtils();
  const isRead = useMemo(() => notification.read, [notification]);

  /**
   * On link click handler
   */
  const onLinkClick = () => {
    if (!notification.read) markAsRead(notification.id);
    /* dispatch(toggleDrawer()); */
  };

  /**
   * Retrieves the time ago value
   */
  const notificationDateAdapter = () => {
    if (DateTime.fromISO(notification.dateCreated).isValid) {
      return timeAgo(DateTime.fromISO(notification.dateCreated));
    }
    return t('common.na');
  };

  /**
   * Returns the template jsx
   */
  const createNotificationTemplate = useCallback(() => {
    const template = <div />;
    if (isDeviceJourney(notification.type)) {
      const data = notification.data as DeviceJourneyData;
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <NotificationTitle>{liveNotificationTitleAdapter(notification)}</NotificationTitle>
          <NotificationBody>
            <Typography
              sx={{
                fontSize: '1rem',
                '> a': {
                  fontSize: '1rem',
                },
              }}
            >
              <Trans
                i18nKey={`common.liveNotifications.messages.${notification.type}`}
                values={{ user: `${data.user.firstName} ${data.user.lastName}`, eSim: data.device.eid }}
                components={{
                  UserLink: (
                    <ClickableLink onClick={() => onLinkClick()} to={`/esimManager/userDetails/${data.user.id}`} />
                  ),
                  ESimLink: (
                    <ClickableLink
                      onClick={() => onLinkClick()}
                      to={`/esimManager/userDetails/${data.user.id}/deviceDetails/${data.device.id}?userName=${data.user.firstName} ${data.user.lastName} `}
                    />
                  ),
                  HelpLink: (
                    <ClickableLink
                      onClick={() => onLinkClick()}
                      to={`${import.meta.env.VITE_LOGOUT_RETURN_URL}${helpUrls[notification.type as DeviceJourney]}`}
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </NotificationBody>
          <NotificationDate>{notificationDateAdapter()}</NotificationDate>
        </Box>
      );
    }
    if (isNotificationCounter(notification.type)) {
      // Doesn't apply at the moment - I think we shouldn't be displaying counter notifications
    }
    return template;
  }, [notification]);

  return (
    <NotificationContainer onClick={() => (!isRead ? markAsRead(notification.id) : () => {})}>
      <Box sx={{ padding: '0.4rem 1rem 1rem 0rem' }}>
        <UnreadNotification read={isRead} />
      </Box>
      {createNotificationTemplate()}
    </NotificationContainer>
  );
}

export default Notification;
