import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LiveNotificationResponse } from '../models/NotificationsModels';

interface ImportStateData {
  liveNotifications: LiveNotificationResponse[];
}

const initialState: ImportStateData = {
  liveNotifications: [],
};

export const LiveNotificationsSlice = createSlice({
  name: 'liveNotifications',
  initialState,
  reducers: {
    queueLiveNotification: (state, action: PayloadAction<LiveNotificationResponse>) => {
      state.liveNotifications = [...state.liveNotifications, action.payload];
    },
    unqueueLiveNotificationById: (state, action: PayloadAction<string>) => {
      state.liveNotifications = state.liveNotifications.filter((notification) => notification.id !== action.payload);
    },
    reset: () => initialState,
  },
});

export const { reset, queueLiveNotification, unqueueLiveNotificationById } = LiveNotificationsSlice.actions;

export default LiveNotificationsSlice.reducer;
