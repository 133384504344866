import { InputAdornment, InputBase, InputLabel, InputLabelProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FeedbackWrapperProps, InputBaseWrapperProps } from './TextInputModels';

export const InputLabelWrapper = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  position: 'relative',
  fontSize: '1.25rem',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '1.75rem',
  transform: 'none',
  color: theme.palette.monochrome.monoChrome6,
  paddingBottom: '0.5rem',
  '&.Mui-focused': {
    color: theme.palette.monochrome.monoChrome6,
  },
}));

export const SubTextWrapper = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  position: 'relative',
  fontSize: '0.875rem',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '1.125rem',
  transform: 'none',
  color: theme.palette.monochrome.monoChrome6,
  paddingBottom: '0.5rem',
  '&.Mui-focused': {
    color: theme.palette.monochrome.monoChrome6,
  },
}));

export const FeedbackWrapper = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'error',
})<FeedbackWrapperProps>(({ theme, error = false }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.125rem',
  paddingTop: '0.5rem',
  display: 'flex',
  color: error ? theme.palette.state.error : 'inherit',
}));

export const InputAdornmentWrapper = styled(InputAdornment)(() => ({
  position: 'absolute',
  left: '0.75rem',
  height: '1.25rem',
  width: '1.25rem',
  margin: 0,
}));

export const InputBaseWrapper = styled(InputBase, {
  shouldForwardProp: (propName) => propName !== 'hasLeftIcon',
})<InputBaseWrapperProps>(({ theme, hasLeftIcon }) => ({
  '&.Mui-error > .MuiInputBase-input': {
    border: `1px solid ${theme.palette.state.error} !important`,
    boxShadow: `inset 0 0 0 1px ${theme.palette.state.error} !important`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.monochrome.monoChrome2,
    borderColor: theme.palette.monochrome.monoChrome3,
  },
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.375rem',
    height: 'calc(56px - 2px) !important',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    color: theme.palette.monochrome.monoChrome6,
    fontWeight: 400,
    lineHeight: '1.75rem',
    border: `1px solid ${theme.palette.monochrome.monoChrome3}`,
    padding: `0rem 1rem 0rem ${hasLeftIcon ? '2.5rem' : '1rem'}`,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      border: `1px solid ${theme.palette.state.focus}`,
      boxShadow: `inset 0 0 0 1px ${theme.palette.state.focus}`,
    },
    '&::placeholder': {
      color: theme.palette.monochrome.monoChrome4,
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.75rem',
    },
  },
}));
