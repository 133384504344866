import { Box, Divider, RadioGroup, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Radio from '../../../components/Radio/Radio';
import SectionTitle from '../../../components/Text/TextStyles';
import { NotificationSetting, NotificationType } from '../../../models/CompanyOnboardingModels';
import { updateImportData } from '../../../stores/ImportStore';
import { RootState } from '../../../stores/Store';
import useImportSummaryStep from './useImportSummaryStep';

const RowsContainer = styled(Box)(() => ({
  paddingBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

const SectionValue = styled(Typography)(() => ({
  lineHeight: 'normal',
  marginLeft: 'auto',
  fontWeight: '700',
  width: '50%',
  textAlign: 'right',
}));

const SectionLabel = styled(Typography)(() => ({
  lineHeight: 'normal',
  width: '50%',
}));

export interface ImportSummaryStepProps {
  onClose: () => void;
}

function ImportSummaryStep({ onClose }: ImportSummaryStepProps) {
  const [t] = useTranslation();
  const { data, qrCodeNotificationSettings, hasDistributionList, importResponse } = useSelector(
    (state: RootState) => state.importReducer,
  );
  const dispatch = useDispatch();
  const { confirmImportNewConnections, isLoading } = useImportSummaryStep();

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as NotificationType;
    dispatch(updateImportData({ qrCodeNotification: val }));
  };

  /**
   * Submit button handler
   * Performs the confirm import of the new connections
   */
  const confirmHandler = () => {
    if (importResponse) {
      confirmImportNewConnections(importResponse.uploadType, importResponse.uploadId, data, onClose);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Summary */}
      <SectionTitle sx={{ paddingTop: '1rem' }}>{t('pages.import.importDrawer.summary')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel>{t('pages.import.importDrawer.numberOfUsers')}</SectionLabel>
          <SectionValue>{importResponse?.numberOfSuccessfulUsers ?? 0}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.import.importDrawer.numberOfDevices')}</SectionLabel>
          <SectionValue>{importResponse?.numberOfSuccessfulEids ?? 0}</SectionValue>
        </Row>
      </RowsContainer>
      <Typography />
      {/* Notification */}
      <SectionTitle sx={{ paddingTop: '1rem' }}>{t('pages.import.importDrawer.notificationSettings')}</SectionTitle>
      <Box sx={{ paddingBottom: '1rem' }}>
        <Typography sx={{ paddingBottom: '1.5rem' }}>{t('pages.import.importDrawer.qrCodeQuestion')}</Typography>
        <RadioGroup
          aria-labelledby="qr-code-destination"
          name="qr-code-destination"
          value={data.qrCodeNotification}
          onChange={handleRadioChange}
        >
          {qrCodeNotificationSettings.map((ns: NotificationSetting) => (
            <Radio
              value={ns.type}
              key={ns.type}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {t(`pages.import.importDrawer.${ns.type}`)}
                  <Typography sx={{ paddingLeft: '1rem', color: (theme) => theme.palette.text.disabled }}>
                    {ns.label}
                  </Typography>
                </Box>
              }
            />
          ))}
        </RadioGroup>
        {qrCodeNotificationSettings.length === 0 ? (
          <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
            {t('pages.import.importDrawer.noNotificationSettings')}
          </Typography>
        ) : null}
      </Box>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.monochrome.monoChrome3,
          marginBottom: '1.5rem',
        }}
      />

      <Box sx={{ marginBottom: '1rem' }}>
        <Checkbox
          checked={data.notifyAdmin}
          onChange={(e) => {
            dispatch(updateImportData({ notifyAdmin: e.currentTarget.checked }));
          }}
          label={t('pages.import.importDrawer.sendMeNotifications')}
        />
      </Box>

      {hasDistributionList ? (
        <Box sx={{ marginBottom: '2.5rem' }}>
          <Checkbox
            checked={data.notifyDistributionList}
            onChange={(e) => {
              dispatch(updateImportData({ notifyDistributionList: e.currentTarget.checked }));
            }}
            label={t('pages.import.importDrawer.sendDLNotifications')}
          />
        </Box>
      ) : null}

      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button
            label={t('common.confirm')}
            color="primaryButton"
            disabled={!importResponse || qrCodeNotificationSettings.length === 0 || isLoading}
            onClick={confirmHandler}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default ImportSummaryStep;
