import * as React from 'react';
import { SVGProps, memo } from 'react';

function WarningIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.806 4.821-7 12.962A2.5 2.5 0 0 0 5 21.48h14a2.5 2.5 0 0 0 2.194-3.697l-7-12.962a2.5 2.5 0 0 0-4.388 0Z"
        clipRule="evenodd"
      />
      <path fill="#BD0000" fillRule="evenodd" d="M12 8.483v7.062-7.062Z" clipRule="evenodd" />
      <path stroke="#BD0000" strokeLinecap="round" strokeLinejoin="round" d="M12 8.483v7.062" />
      <circle cx={12} cy={17.983} r={0.125} fill="#BD0000" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx={12} cy={17.983} r={0.621} fill="#BD0000" />
    </svg>
  );
}
export default memo(WarningIcon);
