import * as React from 'react';
import { SVGProps, memo } from 'react';

function BellIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M9.896 19.098v.297a2.105 2.105 0 1 0 4.209 0v-.293"
      />
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeWidth={0.75}
        d="M13.625 4.72v-.595c0-.897-.727-1.625-1.625-1.625v0a1.584 1.584 0 0 0-1.577 1.579v.641"
      />
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M4.693 19.031a1.33 1.33 0 0 1-1.34-1.21v-.105c0-.542.289-1.043.758-1.315.944-.545 1.578-1.687 1.578-2.855l-.021-2.65a6.314 6.314 0 0 1 6.417-6.314 6.314 6.314 0 0 1 6.422 6.314l-.022 2.65c0 1.168.635 2.31 1.579 2.855.468.272.757.773.757 1.315v.105a1.33 1.33 0 0 1-1.34 1.21H4.693Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
const Memo = memo(BellIcon);
export default Memo;
