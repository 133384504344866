import { Box } from '@mui/material';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../../components/Tabs/Tabs';
import UserDetailsDevicesTable from './UserDetailsDevicesTable';
import UserDetailsSimsTable from './UserDetailsSimsTable';
import useUserDetailsTabs from './useUserDetailsTabs';

function UserDetailsTabs() {
  const [t] = useTranslation();
  const [tabNumber, setTabNumber] = useState(0);
  const tabs = [t('pages.userDetails.tabs.devices'), t('pages.userDetails.tabs.simCards')];
  const { devices, sims, isLoading, resendNotification, isPerformingAction, getDeviceByDeviceId, fetchUserDevices } =
    useUserDetailsTabs();

  /**
   * Sets the tab number
   * @param n
   */
  const handleTabChange = (n: number) => {
    setTabNumber(n);
  };
  return (
    <>
      <Box sx={{ width: '100%', paddingTop: '2.5rem' }}>
        <Tabs tabs={tabs} handleTabChange={handleTabChange} />
      </Box>
      {tabNumber === 0 ? (
        <Box sx={{ padding: '2rem 0' }}>
          {' '}
          <UserDetailsDevicesTable
            devices={devices}
            isLoading={isLoading}
            isPerformingAction={isPerformingAction}
            resendNotification={resendNotification}
          />
        </Box>
      ) : null}
      {tabNumber === 1 ? (
        <Box sx={{ padding: '2rem 0' }}>
          {' '}
          <UserDetailsSimsTable
            sims={sims}
            isLoading={isLoading}
            isPerformingAction={isPerformingAction}
            resendNotification={resendNotification}
            getDeviceById={getDeviceByDeviceId}
            onNewConnectionSuccess={fetchUserDevices}
            onUpgradeSuccess={fetchUserDevices}
            onSuspendSimSuccess={fetchUserDevices}
            onRestoreConnectionSuccess={fetchUserDevices}
            onTerminateConnectionSuccess={fetchUserDevices}
          />
        </Box>
      ) : null}
    </>
  );
}

export default memo(UserDetailsTabs);
