import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StatusTag from '../../../../components/StatusTag/StatusTag';
import { Header, Row } from '../../../../components/Table/TableModels';
import { Sort } from '../../../../models/CommonModels';
import { GetDeviceByIdFn, GetDeviceFn, Sim } from '../../../../models/UserDevicesModels';
import UserDetailsDeviceRowOptions from './UserDetailsDeviceRowOptions';

function useUserDetailsSimsTable(
  sims: Sim[],
  sort: Sort,
  resendNotification: (deviceId: string) => void,
  isPerformingAction: boolean,
  getDeviceById: GetDeviceFn | GetDeviceByIdFn,
  onNewConnectionSuccess: () => void,
  onUpgradeSuccess: () => void,
  onSuspendSimSuccess: () => void,
  onRestoreConnectionSuccess: () => void,
  onTerminateConnectionSuccess: () => void,
) {
  const [t] = useTranslation();

  /**
   * Checks if the options button is needs to be rendered or not
   */
  const canRenderOptions = (sim: Sim) => !(sim.type === 'PSIM' && !sim.phoneNumber) && sim.status !== 'PENDING';

  /**
   * Sorts the array of sims
   */
  const sortBy = useCallback(
    (list: Sim[]): Sim[] => {
      if (list.length) {
        if (!Object.keys(list[0]).includes(sort.orderBy)) {
          throw new Error(`Invalid field name: ${sort.orderBy}`);
        }
        return list.sort((a, b) => {
          let comparison: number;
          try {
            comparison = a[sort.orderBy as keyof Sim].localeCompare(b[sort.orderBy as keyof Sim]);
          } catch (e) {
            comparison = 1;
          }
          return sort.order === 'desc' ? -comparison : comparison;
        });
      }
      return [];
    },
    [sort.order, sort.orderBy],
  );

  const headers: Header[] = useMemo(
    () => [
      {
        id: 'eid',
        label: t('pages.userDetails.tabs.simsTable.headers.simId'),
        isSortable: true,
      },
      {
        id: 'phoneNumber',
        label: t('pages.userDetails.tabs.simsTable.headers.mobileNumber'),
        isSortable: true,
      },
      {
        id: 'type',
        label: t('translation:pages.userDetails.tabs.simsTable.headers.simType'),
        isSortable: true,
      },
      {
        id: 'imei',
        label: t('pages.userDetails.tabs.simsTable.headers.imei'),
        isSortable: true,
      },
      {
        id: 'status',
        label: t('pages.userDetails.tabs.simsTable.headers.status'),
      },
      {
        id: 'options',
        label: '',
      },
    ],
    [t],
  );

  const rows: Row[] = useMemo(
    () =>
      sortBy(sims).map((s) => ({
        id: s.id,
        cells: [
          {
            value: s.simId,
          },
          {
            value: s.phoneNumber,
          },
          {
            value: s.type ? t(`common.simType.${s.type}`) : t('common.na'),
          },
          {
            value: s.imei,
          },
          {
            value: <StatusTag status={s.status} label={t(`common.simStatus.${s.status}`)} />,
          },
          {
            value: canRenderOptions(s) ? (
              <UserDetailsDeviceRowOptions
                sim={s}
                resendNotification={resendNotification}
                isPerformingAction={isPerformingAction}
                getDeviceById={getDeviceById}
                onNewConnectionSuccess={onNewConnectionSuccess}
                onUpgradeSuccess={onUpgradeSuccess}
                onSuspendSimSuccess={onSuspendSimSuccess}
                onRestoreConnectionSuccess={onRestoreConnectionSuccess}
                onTerminateConnectionSuccess={onTerminateConnectionSuccess}
              />
            ) : (
              <div />
            ),
          },
        ],
      })),
    [sims, sortBy, t, isPerformingAction],
  );

  return { headers, rows, totalSims: sims.length };
}

export default useUserDetailsSimsTable;
