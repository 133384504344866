import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LiveNotificationResponse } from '../models/NotificationsModels';

interface NotificationsCenterStateData {
  notifications: LiveNotificationResponse[];
  notificationsCounter: number;
  drawerOpen: boolean;
}

const initialState: NotificationsCenterStateData = {
  notifications: [],
  notificationsCounter: 0,
  drawerOpen: false,
};

export const NotificationsCenter = createSlice({
  name: 'notificationsCenter',
  initialState,
  reducers: {
    setNotificationsCounter: (state, action: PayloadAction<number>) => {
      state.notificationsCounter = action.payload;
    },
    setNotifications: (state, action: PayloadAction<LiveNotificationResponse[]>) => {
      state.notifications = action.payload;
    },
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
    markNotificationAsRead: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.notifications = state.notifications.map((notif) =>
          notif.id === action.payload ? { ...notif, read: true } : notif,
        );
      } else {
        state.notifications = state.notifications.map((notif) => ({ ...notif, read: true }));
      }
    },
    reset: () => initialState,
  },
});

export const { reset, setNotificationsCounter, setNotifications, toggleDrawer, markNotificationAsRead } =
  NotificationsCenter.actions;

export default NotificationsCenter.reducer;
