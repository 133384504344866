import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../stores/NotificationsCenterStore';
import { RootState } from '../../stores/Store';
import BellIcon from '../Icons/BellIcon';
import ClickableIcon from '../Icons/ClickableIcon';
import NotificationsDrawer from './components/NotificationsDrawer';
import NotificationsCounter from './NotificationsCenterSyles';

function NotificationsCenter() {
  const { notificationsCounter } = useSelector((state: RootState) => state.notificationsCenterReducer);
  const dispatch = useDispatch();

  return (
    <>
      <ClickableIcon
        onClick={() => {
          dispatch(toggleDrawer());
        }}
        sx={{ flexShrink: 0, position: 'relative' }}
      >
        <BellIcon />
        <NotificationsCounter counter={notificationsCounter}>{notificationsCounter}</NotificationsCounter>
      </ClickableIcon>
      <NotificationsDrawer />
    </>
  );
}

export default NotificationsCenter;
