import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { NotificationSetting } from '../../../models/CompanyOnboardingModels';
import { UploadType } from '../../../models/ImportModels';
import CompanyOnboardingService from '../../../services/CompanyOnboarding/CompanyOnboardingService';
import ImportService from '../../../services/Import/ImportService';
import { setImportResponse, setNotificationSettings } from '../../../stores/ImportStore';

function useImportFileStep() {
  const dispatch = useDispatch();
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Retrieves only the types of notification that are enabled
   * @param notifications
   */
  const filterEnabledQrCodeNotifications = (notifications: NotificationSetting[]): NotificationSetting[] =>
    notifications.filter((notification) => notification.enabled);

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   * @param nextStep
   */
  const getNotificationSettings = (companyId: string, nextStep: (step: number) => void) => {
    setIsLoading(true);
    CompanyOnboardingService.api
      .fetchCompanyNotificationSettings(companyId)
      .then(
        (res) => {
          dispatch(setNotificationSettings(filterEnabledQrCodeNotifications(res.data.qrCodeNotifications)));
          nextStep(3);
        },
        () => {
          snackBar.showSnackBar(
            t('pages.eSimManager.newConnectionDrawer.fetchCompanyNotificationsDefaultError'),
            'error',
          );
        },
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Uploads and handles the response of the csv file to check for issues
   * @param file
   * @param nextStep
   * @param onClose
   */
  const uploadFile = (file: File, nextStep: (step: number) => void, onClose: () => void) => {
    setIsLoading(true);
    dispatch(setImportResponse(null));
    ImportService.api.uploadFile({ file }).then(
      (res) => {
        if (res.data.errorLines && res.data.errorLines.length > 0) {
          dispatch(setImportResponse(res.data));
          setIsLoading(false);
          nextStep(2);
        } else if (res.data.uploadType === UploadType.NEW_CONNECTIONS) {
          dispatch(setImportResponse(res.data));
          getNotificationSettings(res.data.companyId, nextStep);
        } else {
          setIsLoading(false);
          snackBar.showSnackBar(t('pages.import.uploadSuccess'), 'success');
          onClose();
          navigate('/esimmanager');
        }
      },
      () => {
        snackBar.showSnackBar(t('pages.import.uploadError'), 'error');
        setIsLoading(false);
      },
    );
  };

  return { getNotificationSettings, isLoading, uploadFile };
}

export default useImportFileStep;
