import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import LocalAuthTokenUrl from '../../configs/MockConfig';
import AuthResponse from '../../models/AuthModels';

const AuthService = {
  api: {
    renewCIAMSession: () =>
      fetch(import.meta.env.VITE_RENEW_SESSION_URL, {
        method: 'GET',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Requested-With, X-Auth-Token, content-type',
        },
      }),
    logout: (): AxiosPromise => Api.get('/authenticate/logout'),
    authenticate: (): AxiosPromise<AuthResponse> =>
      Api.get(import.meta.env.VITE_ENVIRONMENT === 'local' ? LocalAuthTokenUrl : `/authenticate/token`, {}),
  },
};

export default AuthService;
