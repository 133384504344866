import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import './App.css';
import './configs/i18nConfig';
import Theme from './configs/Themes';
import AuthProvider from './contexts/AuthContext/AuthContext';
import ModalProvider from './contexts/ModalContext/ModalContext';
import Router from './Router';
import { Store } from './stores/Store';

function App() {
  const theme = createTheme(Theme);
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ModalProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
