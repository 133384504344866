import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationSetting } from '../models/CompanyOnboardingModels';
import { TerminateConnectionRequest } from '../models/UserDevicesModels';

interface UpgradeDeviceStateData {
  request: TerminateConnectionRequest;
  qrCodeNotificationSettings: NotificationSetting[];
  hasDistributionList: boolean;
}

const initialState: UpgradeDeviceStateData = {
  request: {
    notifyAdmin: true,
    eid: '',
    userId: '',
    phoneNumber: '',
    notificationRecipient: 'ADMIN',
    notifyDistributionList: false,
    suspendImmediately: false,
    terminationDate: '',
  },
  qrCodeNotificationSettings: [],
  hasDistributionList: false,
};

export const TerminateConnection = createSlice({
  name: 'terminateConnection',
  initialState,
  reducers: {
    updateRequest: (state, action: PayloadAction<Partial<TerminateConnectionRequest>>) => {
      state.request = {
        ...state.request,
        ...action.payload,
      };
    },
    setNotificationSettings: (state, action: PayloadAction<NotificationSetting[]>) => {
      state.hasDistributionList = action.payload.some((setting) => setting.type === 'DISTRIBUTION_LIST');
      state.qrCodeNotificationSettings = action.payload;
    },
    reset: () => initialState,
  },
});

export const { updateRequest, setNotificationSettings, reset } = TerminateConnection.actions;

export default TerminateConnection.reducer;
