import { Box, FormControl, SxProps } from '@mui/material';
import { DateField as DF, DateFieldProps as DFProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import NotificationErrorIcon from '../Icons/NotificationErrorIcon';
import { FeedbackWrapper, InputBaseWrapper, InputLabelWrapper } from '../TextInput/TextInputStyle';

interface DateFieldProps extends DFProps<DateTime> {
  error?: boolean;
  feedback?: JSX.Element | string;
  formStyle?: SxProps;
  triggerClear?: boolean;
}
function DateField(props: DateFieldProps) {
  const [componentKey, setComponentKey] = useState(Date.now());

  useEffect(() => {
    // forces the reset of the component when a reset filter is applied
    setComponentKey(Date.now());
  }, [props.triggerClear]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <FormControl sx={{ width: '100%', ...props.formStyle }}>
        {props.label ? <InputLabelWrapper shrink>{props.label}</InputLabelWrapper> : null}
        <DF
          key={componentKey}
          format={props.format ?? '    dd    |    LL    |    y    '}
          onError={props.onError}
          onChange={props.onChange}
          fullWidth
          clearable
          value={props.value}
          formatDensity="spacious"
          slots={{
            textField: InputBaseWrapper,
          }}
          slotProps={{
            textField: { error: props.error },
          }}
          sx={{
            '& input': {
              /* textAlign: 'center', */
            },
          }}
        />
        {props.feedback ? (
          <FeedbackWrapper error={props.error} component="span">
            {props.error ? (
              <Box sx={{ paddingRight: '0.25rem', flexShrink: 0 }}>
                <NotificationErrorIcon />
              </Box>
            ) : null}
            {props.feedback}
          </FeedbackWrapper>
        ) : null}
      </FormControl>
    </LocalizationProvider>
  );
}

export default DateField;
