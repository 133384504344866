import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../../components/Icons/CalendarIcon';
import PageTitle from '../../components/Text/PageTitle';
import useAuth from '../../hooks/useAuth/useAuth';
import DashboardCards from './components/DashboardCards';
import LatestTransactions from './LatestTransactions/LatestTransactions';

function Dashboard() {
  const [t] = useTranslation();
  const { userData } = useAuth();
  const date = new Date();
  const formattedDate = date.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: '2-digit' });

  /* const addDummyNotification = () => {
    LiveNotificationsService.api.addDummyNotification({
      adminId: 'cff50c1f-4202-4373-b28b-581f6301993d',
      status: 'ERROR',
      actionId: `7f1e3844-6b2d-4f3d-8aff-6a8eaf673527`,
    });
  }; */

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageTitle>{t('pages.dashboard.title')}</PageTitle>
          <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
            <CalendarIcon />
            <Typography sx={{ paddingLeft: '0.5rem', fontSize: '1.375rem', fontHeight: 'normal' }}>
              {t('pages.dashboard.today')}, {formattedDate}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', paddingTop: '0.5rem' }}>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: 'normal' }}>
            {t('pages.dashboard.welcome')} {userData?.firstName}
          </Typography>
          {/* <Button onClick={addDummyNotification} label="add dummy" /> */}
        </Box>
      </Box>
      <DashboardCards />
      <LatestTransactions />
    </>
  );
}

export default Dashboard;
