import { useContext } from 'react';
import { SideToastContext } from '../../contexts/SideToastContext/SideToastContext';

function useSideToast() {
  const context = useContext(SideToastContext);
  if (!context) {
    throw new Error('useSideToast must be used within an SideToastProvider');
  }
  return context;
}
export default useSideToast;
