import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table/Table';
import TablePagination from '../../components/TablePagination/TablePagination';
import { Sort } from '../../models/CommonModels';
import UemsTableFilters, { UemFilter } from './UemsTableFilters';
import useUemsTable from './useUemsTable';

function UemsTable() {
  const [t] = useTranslation();
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState<UemFilter>({ filter: '', type: '' });
  const [sort, setSort] = useState<Sort>({ order: 'asc', orderBy: '' });
  const { headers, rows, totalUems, isLoading } = useUemsTable(activePage, pageSize, filter, sort);

  /**
   * On pagination change
   * Perform a new request
   * @param page
   * @param pSize
   */
  const handlePaginationChange = (page: number, pSize: number) => {
    setActivePage(page);
    setPageSize(pSize);
  };

  /**
   * On search click
   * @param uemFilter
   */
  const handleSearch = (uemFilter: UemFilter) => {
    setActivePage(1);
    setFilter(uemFilter);
  };

  /**
   * Handle change of the table sort
   * @param s
   */
  const handleOnSort = (s: Sort) => {
    setSort(s);
  };

  return (
    <Box paddingBottom="3rem">
      <UemsTableFilters onSearch={handleSearch} isLoading={isLoading} filter={filter} />
      <Typography variant="body1" paddingBottom="1rem">
        {t('common.table.totalItems', { count: totalUems })}
      </Typography>
      <Table
        headers={headers}
        rows={rows}
        isLoading={isLoading}
        emptyStateMessage={
          filter.type === '' ? t('pages.uemOnboarding.table.selectUemType') : t('common.table.emptyState')
        }
        defaultOrderBy={sort.orderBy}
        onSort={handleOnSort}
      />
      <TablePagination
        activePage={activePage}
        pageSize={pageSize}
        totalItems={totalUems}
        handlePageChange={handlePaginationChange}
      />
    </Box>
  );
}

export default UemsTable;
