import { Box, BoxProps, styled } from '@mui/material';

interface AlertContainerProps extends BoxProps {
  colors: { bg: string; font: string };
}
export const AlertMessage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  alignItems: 'center',
}));

export const AlertContainer = styled('div')<AlertContainerProps>(({ colors }) => ({
  display: 'flex',
  padding: '1rem',
  backgroundColor: colors.bg,
  borderRadius: '6px',
}));
