import { Box, BoxProps, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChevronRightSmIcon } from '../../../components/Icons/ChevronRightIcon';

const CardContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem',
  borderRadius: '0.75rem',
  boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.15)',
}));
interface DashboardCardProps {
  icon: React.JSX.Element;
  title: string;
  description: string;
  value: string | number | undefined;
  color: string;
  to?: string;
  onClick: () => void;
  showAction?: boolean;
}
function DashboardCard({
  icon,
  title,
  description,
  value,
  color,
  onClick,
  to = '',
  showAction = true,
}: DashboardCardProps) {
  const [t] = useTranslation();
  const theme = useTheme();
  return (
    <Grid item xl={4} md={6} xs={12} sx={{ paddingBottom: '2rem' }}>
      <CardContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '1.5rem' }}>
          {icon}
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '0.5rem' }}>
            <Typography sx={{ fontWeight: 'bold', lineHeight: 'normal' }}>{title}</Typography>
            <Typography sx={{ lineHeight: 'normal' }}>{description}</Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: '3.5rem',
            paddingBottom: '1.5rem',
            lineHeight: 'normal',
            color,
          }}
        >
          {value === undefined ? <Skeleton width={50} /> : value}
        </Typography>
        {showAction ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={onClick}>
            <Link to={to} style={{ textDecoration: 'none' }}>
              <Typography sx={{ color: theme.palette.state.linkHover, lineHeight: 'normal', cursor: 'pointer' }}>
                {t('pages.dashboard.cards.seeAll')}
              </Typography>
            </Link>
            <Box sx={{ paddingLeft: '0.5rem', cursor: 'pointer' }}>
              <ChevronRightSmIcon />
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: '21px' }} />
        )}
      </CardContainer>
    </Grid>
  );
}

export default DashboardCard;
