import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header, Row } from '../../../components/Table/TableModels';
import { RootState } from '../../../stores/Store';

function useImportStep() {
  const [t] = useTranslation();
  const { importResponse } = useSelector((state: RootState) => state.importReducer);
  const headers: Header[] = useMemo(
    () => [
      {
        id: 'name',
        label: t('translation:pages.import.line'),
        isSortable: false,
      },

      {
        id: 'issue',
        label: t('pages.import.issue'),
        isSortable: false,
      },
    ],
    [t],
  );

  const rows: Row[] = useMemo(() => {
    if (importResponse && importResponse.errorLines) {
      return importResponse.errorLines.map((errorLine) => ({
        id: `row-${errorLine.line}`,
        cells: [
          {
            value: errorLine.line,
          },
          {
            value: (
              <>
                {errorLine.reasons.map((reason) => (
                  <Typography key={`line-${reason}`}>{t(`common.errors.httpReason.${reason}`)}</Typography>
                ))}
              </>
            ),
          },
        ],
      }));
    }
    return [];
  }, [t, importResponse?.errorLines]);

  return { headers, rows };
}

export default useImportStep;
