import { AlertColor } from '@mui/material';
import { DateTime } from 'luxon';
import React, { createContext, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { QueryParams } from 'react-use-websocket/dist/lib/types';
import LiveNotificationsUrl from '../../configs/WebSocketsConfig';
import useAuth from '../../hooks/useAuth/useAuth';
import UseLogger from '../../hooks/useLogger/useLogger';
import useSideToast from '../../hooks/useSideToast/useSideToast';
import { LiveNotificationResponse, NotificationCounterData } from '../../models/NotificationsModels';
import WsConnectionStatus from '../../models/WebsocketsModels';
import LiveNotificationsService from '../../services/LiveNotifications/LiveNotificationsService';
import { queueLiveNotification, unqueueLiveNotificationById } from '../../stores/LiveNotificationsStore';
import { setNotificationsCounter } from '../../stores/NotificationsCenterStore';
import { RootState } from '../../stores/Store';
import { SideToastProps } from '../SideToastContext/SideToastContext';
import LiveNotificationsTemplate from './LiveNotificationsTemplate';
import useLiveNotificationsUtils from './useLiveNotificationsUtils';

interface LiveNotificationsContextData {
  liveNotifications: LiveNotificationResponse[];
}
export const LiveNotificationsContext = createContext({} as LiveNotificationsContextData);

function LiveNotificationsProvider({ children }: PropsWithChildren) {
  const firstUpdate = useRef(true);
  const { liveNotifications } = useSelector((state: RootState) => state.liveNotificationsReducer);
  const { liveNotificationTitleAdapter, isNotificationCounter } = useLiveNotificationsUtils();
  const dispatch = useDispatch();
  const { showSideToast, closeSideToast, sideToastState } = useSideToast();
  const { jwt } = useAuth();
  const [socketParams, setSocketParams] = useState<QueryParams>();
  const [sendSocketParams, setSendSocketParams] = useState<boolean>(false);
  const { lastMessage, lastJsonMessage, readyState } = useWebSocket(LiveNotificationsUrl, {
    protocols: ['Authorization', jwt ?? ''],
    shouldReconnect: () => true,
    queryParams: sendSocketParams ? socketParams : {},
  });

  React.useEffect(() => {
    const wsStatus = WsConnectionStatus[readyState];
    UseLogger().logInfo(`LiveNotificationsContext: websocket status changed ${wsStatus}`);
    // On subsequent requests, we send the initial timestamp when the app was started
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setSocketParams({ timestamp: `${DateTime.now().toUnixInteger()}` });
    } else if (!firstUpdate.current && readyState === ReadyState.CLOSED) {
      setSendSocketParams(true);
    }
  }, [readyState]);

  React.useEffect(() => {
    if (lastJsonMessage) {
      /* UseLogger().log(`LiveNotificationsContext: websocket message ${JSON.stringify(lastJsonMessage)}`); */
      dispatch(queueLiveNotification(lastJsonMessage as LiveNotificationResponse));
      if (isNotificationCounter((lastJsonMessage as LiveNotificationResponse).type)) {
        dispatch(
          setNotificationsCounter(
            ((lastJsonMessage as LiveNotificationResponse).data as NotificationCounterData).counter,
          ),
        );
      }
    }
  }, [lastJsonMessage, lastMessage]);

  /**
   * Makes a request to mark a notification as read'
   * @param id
   */
  const markNotificationAsRead = (id: string) => {
    LiveNotificationsService.api.markNotificationAsRead({ notifications: [id] }).then(
      (res) => {
        dispatch(setNotificationsCounter(res.data.unreadNotifications));
      },
      (err) => {
        UseLogger().logError(`LiveNotificationsContext: Failed to mark notification ${id} as read! Error: ${err}`);
      },
    );
  };

  /**
   * Handler for on navigate events
   * @param id
   */
  const onNavigate = (id: string) => {
    closeSideToast(id);
    dispatch(unqueueLiveNotificationById(id));
    markNotificationAsRead(id);
  };

  /**
   * Handler for the onClose live notification
   * @param id
   */
  const onCloseLiveNotification = (id: string) => {
    dispatch(unqueueLiveNotificationById(id));
    markNotificationAsRead(id);
  };

  /**
   * Creates the options object to display on the side toast liveNotification
   * @param liveNotification
   */
  const liveNotificationToSideToastOptionsAdapter = (liveNotification: LiveNotificationResponse) => {
    const options: SideToastProps = {
      id: liveNotification.id,
      title: liveNotificationTitleAdapter(liveNotification),
      template: <LiveNotificationsTemplate liveNotification={liveNotification} onNavigate={onNavigate} />,
      severity: liveNotification.status.toLowerCase() as AlertColor,
      onClose: () => {
        onCloseLiveNotification(liveNotification.id);
      },
    };
    return options;
  };

  /**
   * Iterates through the queue of pending liveNotifications, showing them one by one
   */
  useEffect(() => {
    if (liveNotifications.length && !sideToastState.open) {
      showSideToast(liveNotificationToSideToastOptionsAdapter(liveNotifications[0]));
    }
  }, [liveNotifications]);

  const value = useMemo(
    () => ({
      liveNotifications,
    }),
    [liveNotifications],
  );

  return <LiveNotificationsContext.Provider value={value}>{children}</LiveNotificationsContext.Provider>;
}
export default LiveNotificationsProvider;
