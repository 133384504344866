import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationSetting, NotificationType } from '../models/CompanyOnboardingModels';
import { ImportResponse } from '../models/ImportModels';

export interface DataParams {
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  qrCodeNotification: NotificationType;
}

interface ImportStateData {
  data: DataParams;
  importResponse: ImportResponse | null;
  qrCodeNotificationSettings: NotificationSetting[];
  hasDistributionList: boolean;
}

const initialState: ImportStateData = {
  data: {
    notifyAdmin: true,
    notifyDistributionList: false,
    qrCodeNotification: 'ADMIN',
  },
  importResponse: null,
  qrCodeNotificationSettings: [],
  hasDistributionList: false,
};

export const ImportSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    reset: () => initialState,
    updateImportData: (state, action: PayloadAction<Partial<DataParams>>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setImportResponse: (state, action: PayloadAction<ImportResponse | null>) => {
      state.importResponse = action.payload;
    },
    setNotificationSettings: (state, action: PayloadAction<NotificationSetting[]>) => {
      state.hasDistributionList = action.payload.some((setting) => setting.type === 'DISTRIBUTION_LIST');
      state.qrCodeNotificationSettings = action.payload;
    },
  },
});

export const { reset, updateImportData, setImportResponse, setNotificationSettings } = ImportSlice.actions;

export default ImportSlice.reducer;
