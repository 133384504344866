import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import { AuditTrailColumns } from '../../models/AuditTrailModels';
import { DevicesCounterResponse, LatestTransactionsResponse, UsersCounterResponse } from '../../models/DashboardModels';

const DashboardService = {
  api: {
    getUsersCounter: (): AxiosPromise<UsersCounterResponse> => Api.get(`/dashboard/users/counter`),
    getDevicesCounter: (): AxiosPromise<DevicesCounterResponse> => Api.get(`/dashboard/devices/counter`),
    fetchLatestTransactions: (): AxiosPromise<LatestTransactionsResponse> => Api.get(`/dashboard/audit/latest`),
    fetchLatestTransactionColumns: (): AxiosPromise<AuditTrailColumns> => Api.get(`/dashboard/audit/columns`, {}),
  },
};

export default DashboardService;
