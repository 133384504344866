import { ApiResponse } from './ServiceModels';

/**
 * API response for the POST /upload-file to import users or devices
 */
export interface ImportResponse extends ApiResponse {
  errorLines: ErrorLine[];
  successfulLines: number;
  uploadType: UploadType;
  uploadId: string;
  numberOfErrorUsers?: number;
  numberOfErrorEids?: number;
  numberOfSuccessfulUsers?: number;
  numberOfSuccessfulEids?: number;
  companyId: string;
}

export interface ErrorLine {
  line: number;
  reasons: number[];
}

/**
 * API request to import users or devices
 */
export interface ImportRequest {
  file: File;
}

/**
 * Types of file upload templates
 */
export enum UploadType {
  'USERS' = 'USERS',
  'DEVICES' = 'DEVICES',
  'NEW_CONNECTIONS' = 'NEW_CONNECTIONS',
}

/**
 * Mapping of upload type to file name
 */
export const UploadFileNames = {
  [UploadType.USERS]: 'USERS',
  [UploadType.DEVICES]: 'DEVICES',
  [UploadType.NEW_CONNECTIONS]: 'NEW_CONNECTIONS',
};

/**
 * Request interface for the confirm new connections import
 */
export interface ConfirmNewConnectionsRequest {
  uploadId: string;
  uploadType: UploadType;
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
}
