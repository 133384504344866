import { Box, styled, Typography } from '@mui/material';

export const NotificationTitle = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  paddingBottom: '0.5rem',
}));

export const NotificationContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.disabled.main}`,
  padding: '1rem',
  display: 'flex',
  flexDirection: 'row',
}));

export const NotificationBody = styled('div')(() => ({}));
export const NotificationDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  paddingTop: '0.5rem',
  fontSize: '1rem',
}));

interface UnreadNotificationProps {
  read: boolean;
}
export const UnreadNotification = styled('div')<UnreadNotificationProps>(({ theme, read }) => ({
  fontWeight: 'bold',
  borderRadius: '50%',
  backgroundColor: read ? 'transparent' : theme.palette.primaryRadio.main,
  width: '0.5rem',
  height: '0.5rem',
}));
