import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Alert from '../../../components/Alert/Alert';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Table from '../../../components/Table/Table';

import useImportErrorsStep from './useImportErrorsStep';

export interface ImportErrorsStepProps {
  nextStep: (step: number) => void;
  onClose: () => void;
}

function ImportErrorsStep({ nextStep, onClose }: ImportErrorsStepProps) {
  const [t] = useTranslation();
  const { rows, headers } = useImportErrorsStep();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Alert alertType="error" message={t('pages.import.importFailedTitle')} />
      <Typography sx={{ marginTop: '2.5rem', marginBottom: '1rem' }}>
        {t('pages.import.importFailedDescription')}
      </Typography>
      {/* Table */}
      <Table rows={rows} headers={headers} />
      <Typography sx={{ marginTop: '2.5rem', marginBottom: '1rem' }}>{t('pages.import.pleaseCorrect')}</Typography>
      {/* Buttons */}
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button label={t('common.back')} color="primaryButton" onClick={() => nextStep(1)} />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default ImportErrorsStep;
