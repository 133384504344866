import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useSnackBar from '../../../../../hooks/useSnackBar/useSnackBar';
import { NotificationSetting } from '../../../../../models/CompanyOnboardingModels';
import CompanyOnboardingService from '../../../../../services/CompanyOnboarding/CompanyOnboardingService';
import { RootState } from '../../../../../stores/Store';
import { setNotificationSettings } from '../../../../../stores/TerminateConnectionStore';

function useTerminateConnection() {
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { deviceToUpgrade } = useSelector((state: RootState) => state.upgradeDeviceReducer);

  /**
   * Retrieves only the types of notification that are enabled
   * @param notifications
   */
  const filterEnabledQrCodeNotifications = (notifications: NotificationSetting[]): NotificationSetting[] =>
    notifications.filter((notification) => notification.enabled);

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   */
  const getNotificationSettings = (companyId: string) => {
    CompanyOnboardingService.api.fetchCompanyNotificationSettings(companyId).then(
      (res) => {
        dispatch(setNotificationSettings(filterEnabledQrCodeNotifications(res.data.qrCodeNotifications)));
      },
      () => {
        snackBar.showSnackBar(
          t('pages.userDetails.terminateConnectionDrawer.fetchCompanyNotificationsDefaultError'),
          'error',
        );
      },
    );
  };

  useEffect(() => {
    if (deviceToUpgrade && deviceToUpgrade.companyId) getNotificationSettings(deviceToUpgrade.companyId);
  }, [deviceToUpgrade]);

  return { getNotificationSettings };
}

export default useTerminateConnection;
