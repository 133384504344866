import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { LocaleDateFormatConfig, LocaleDateTimeFormatConfig } from '../../configs/DateConfig';

function useDate() {
  const { language } = i18next;
  const localeDateTimeFormat: string = useMemo(() => {
    if (Object.hasOwn(LocaleDateTimeFormatConfig.format, language)) {
      return LocaleDateTimeFormatConfig.format[language];
    }
    return LocaleDateTimeFormatConfig.format.en;
  }, [language]);

  const localeDateFormat: string = useMemo(() => {
    if (Object.hasOwn(LocaleDateFormatConfig.format, language)) {
      return LocaleDateFormatConfig.format[language];
    }
    return LocaleDateFormatConfig.format.en;
  }, [language]);

  const units: Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

  /**
   * Retrieves the time ago based on a dateTime
   * @param dateTime
   */
  const timeAgo = (dateTime: DateTime) => {
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((un) => diff.get(un) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
      numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
  };

  return { localeDateTimeFormat, localeDateFormat, timeAgo };
}

export default useDate;
