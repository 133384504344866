import { Box, Skeleton, styled, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import ButtonGroup from '../../../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import SectionTitle from '../../../../../components/Text/TextStyles';
import UserDetailsContext from '../../../../../contexts/UserDetailsContext/UserDetailsContext';
import { Device, GetDeviceByIdFn, GetDeviceFn, Sim } from '../../../../../models/UserDevicesModels';

const RowsContainer = styled(Box)(() => ({
  paddingBottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

const SectionValue = styled(Typography)(() => ({
  lineHeight: 'normal',
  marginLeft: 'auto',
  fontWeight: '700',
  width: '50%',
  textAlign: 'right',
}));

const SectionLabel = styled(Typography)(() => ({
  lineHeight: 'normal',
  width: '50%',
}));

interface SuspendSimSummaryStepProps {
  nextStep: (notifyAdmin: boolean, notifyDL: boolean, phoneNumber: string) => void;
  onClose: () => void;
  isLoading: boolean;
  isLoadingNotifications: boolean;
  sim: Sim;
  hasDL: boolean;
  device?: Device | null;
}

function SuspendSimSummaryStep({
  nextStep,
  onClose,
  isLoading,
  isLoadingNotifications,
  sim,
  device,
  hasDL,
}: SuspendSimSummaryStepProps) {
  const [t] = useTranslation();
  const user = useContext(UserDetailsContext);
  const [notifyAdmin, setNotifyAdmin] = useState(false);
  const [notifyDL, setNotifyDL] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Summary */}
      <SectionTitle>{t('pages.userDetails.suspendSimDrawer.summary')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel>{t('pages.userDetails.suspendSimDrawer.userName')}</SectionLabel>
          <SectionValue>{`${user?.firstName} ${user?.lastName}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>{t('pages.userDetails.suspendSimDrawer.eSimId')}</SectionLabel>
          <SectionValue sx={{ width: '75%' }}>{sim.simId}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.userDetails.suspendSimDrawer.mobileNumber')}</SectionLabel>
          <SectionValue>{sim.phoneNumber}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.userDetails.suspendSimDrawer.device')}</SectionLabel>
          <SectionValue>{`${device?.manufacturer} ${device?.model}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.userDetails.suspendSimDrawer.imei')}</SectionLabel>
          <SectionValue>{sim.imei}</SectionValue>
        </Row>
      </RowsContainer>

      {/* Notification */}
      {isLoadingNotifications ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: '2rem' }}>
          <Skeleton width="65%" height="2rem" />
          <Skeleton width="90%" height="2rem" />
        </Box>
      ) : (
        <Box sx={{ paddingBottom: '2rem' }}>
          <Checkbox
            onChange={(e) => {
              setNotifyAdmin(e.currentTarget.checked);
            }}
            label={t('pages.userDetails.suspendSimDrawer.sendNotification')}
          />
          {hasDL ? (
            <Checkbox
              sx={{ paddingTop: '1rem' }}
              onChange={(e) => {
                setNotifyDL(e.currentTarget.checked);
              }}
              label={t('pages.userDetails.suspendSimDrawer.sendDLNotification')}
            />
          ) : null}
        </Box>
      )}
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} disabled={isLoading} />
          <Button
            label={t('common.confirm')}
            color="primaryButton"
            onClick={() => nextStep(notifyAdmin, notifyDL, sim.phoneNumber)}
            disabled={isLoading}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default SuspendSimSummaryStep;
