import { configureStore } from '@reduxjs/toolkit';
import UpgradeDeviceReducer from './UpgradeDeviceStore';
import NewConnectionReducer from './NewConnectionStore';
import DevicesReportReducer from './DevicesReportStore';
import AuditTrailReducer from './AuditTrailStore';
import ImportReducer from './ImportStore';
import TerminateConnectionReducer from './TerminateConnectionStore';
import LiveNotificationsReducer from './LiveNotificationsStore';
import NotificationsCenterReducer from './NotificationsCenterStore';

export const Store = configureStore({
  reducer: {
    devicesReportReducer: DevicesReportReducer,
    upgradeDeviceReducer: UpgradeDeviceReducer,
    newConnectionReducer: NewConnectionReducer,
    auditTrailReducer: AuditTrailReducer,
    importReducer: ImportReducer,
    terminateConnectionReducer: TerminateConnectionReducer,
    liveNotificationsReducer: LiveNotificationsReducer,
    notificationsCenterReducer: NotificationsCenterReducer,
  },
});
export type RootState = ReturnType<typeof Store.getState>;
