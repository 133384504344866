import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Navigate, ScrollRestoration } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import TopBanner from '../../components/TopBanner/TopBanner';
import LiveNotificationsProvider from '../../contexts/LiveNotificationsContext/LiveNotificationsContext';
import SessionExpiryProvider from '../../contexts/SessionExpiryContext/SessionExpiryContext';
import SideToastProvider from '../../contexts/SideToastContext/SideToastContext';
import SnackBarProvider from '../../contexts/SnackBarContext/SnackBarContext';
import useAuthenticatedRouteGuard from '../../hooks/useAuthenticatedRouteGuard/useAuthenticatedRouteGuard';
import { UserRole } from '../../models/AuthModels';

interface AuthenticatedRouteProps extends PropsWithChildren {
  allowedRoles: UserRole[];
}
function AuthenticatedRoute({ allowedRoles, children }: AuthenticatedRouteProps) {
  const { allowRender } = useAuthenticatedRouteGuard(allowedRoles);
  return (
    <SessionExpiryProvider>
      <SnackBarProvider>
        <SideToastProvider>
          <LiveNotificationsProvider>
            <ScrollRestoration />
            <Navbar />
            <TopBanner />
            <Container maxWidth="xl" sx={{ marginTop: '2.5rem', minHeight: '70vh' }}>
              {allowRender ? <>{children} </> : <Navigate to="/notAllowed" />}
            </Container>
            <Footer />
          </LiveNotificationsProvider>
        </SideToastProvider>
      </SnackBarProvider>
    </SessionExpiryProvider>
  );
}

export default AuthenticatedRoute;
