import { styled } from '@mui/material/styles';

interface NotificationsCounterProps {
  counter: number;
}

const NotificationsCounter = styled('div')<NotificationsCounterProps>(({ theme, counter }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontSize: counter > 9 ? '7.5px' : '9px',
  position: 'absolute',
  top: '8px',
  right: '5px',
  transform: 'translate(50%, -50%)',
}));

export default NotificationsCounter;
