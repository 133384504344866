import { Divider, Grid, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../components/Dropdown/Dropdown';
import SearchInput from '../../components/SearchInput/SearchInput';
import useUemsTableFilters from './useUemsTableFilters';

interface UemsTableFiltersProps {
  onSearch: (filter: UemFilter) => void;
  isLoading: boolean;
  filter: UemFilter;
}

export interface UemFilter {
  filter: string;
  type: string;
}

function UemsTableFilters({ onSearch, isLoading = false, filter }: UemsTableFiltersProps) {
  const [t] = useTranslation();
  const { uemTypesOptions, uemTypes } = useUemsTableFilters();
  const theme = useTheme();

  React.useEffect(() => {
    /* if (uemTypes && uemTypes.length) {
      onSearch({ ...filter, type: uemTypes[0].type });
    } */
  }, [uemTypes]);

  /**
   * Search handler
   * @param f
   * @param type
   */
  const search = (f: string, type: string) => {
    onSearch({ filter: f, type });
  };

  return (
    <>
      <Grid container sx={{ paddingTop: '1.5rem' }}>
        <Grid item xl={5} md={6} xs={12}>
          <Dropdown
            id="type"
            name="type"
            label={t('pages.uemOnboarding.uemType')}
            value={filter.type}
            onChange={(e) => {
              search(filter.filter, e.target.value as string);
            }}
            items={uemTypesOptions}
            placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.uemOnboarding.uemType')}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{ borderColor: theme.palette.monochrome.monoChrome3, marginTop: '2.5rem', marginBottom: '2.5rem' }}
      />
      <Grid container sx={{ paddingBottom: '1rem' }}>
        <Grid item xl={5} md={6} xs={12}>
          <SearchInput
            label={t('pages.uemOnboarding.table.filters.search')}
            onSearch={(q) => {
              search(q, filter.type);
            }}
            placeholder={t('pages.uemOnboarding.table.filters.searchPlaceholder')}
            isButtonDisabled={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UemsTableFilters;
