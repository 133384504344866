import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../../components/Table/TableModels';
import useDate from '../../../hooks/useDate/useDate';
import { AuditTrail } from '../../../models/AuditTrailModels';
import { BillingAccount } from '../../../models/CompanyOnboardingModels';
import DashboardService from '../../../services/Dashboard/DashboardService';

function useLatestTransactions() {
  const [tableHeaders, setTableHeaders] = useState([
    'creationDate',
    'eventType',
    'eventSubType',
    'status',
    'adminFirstName',
    'adminLastName',
    'adminEmail',
    'companyName',
    'country',
    'ban',
    'deviceSource',
    'endUserFirstName',
    'endUserLastName',
    'endUserEmail',
    'endAdminEmail',
    'endAdminRole',
    'tariffPlan',
    'deviceType',
    'deviceManufacturer',
    'deviceModel',
    'deviceSerialNumber',
    'eid',
    'uemDeviceId',
    'uemSystem',
    'operationType',
  ]);
  const [t] = useTranslation();
  const [transactions, setTransactions] = useState<AuditTrail[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { localeDateTimeFormat } = useDate();

  /**
   * Fetch latest transactions
   */
  const fetchLatestTransactions = () => {
    setIsLoading(true);
    DashboardService.api
      .fetchLatestTransactions()
      .then(
        (response) => {
          setTransactions(response.data.auditTrailList);
        },
        () => {},
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Render the value of each cell based on type of field
   * @param auditTrail
   * @param item
   */
  const renderCellValue = (auditTrail: AuditTrail, item: keyof AuditTrail) => {
    if (auditTrail[item]) {
      switch (item) {
        case 'creationDate':
          return DateTime.fromISO(auditTrail[item]).toFormat(`${localeDateTimeFormat} (z)`);
        case 'eventType':
          return t(`pages.auditTrail.eventTypes.${auditTrail[item]}`);
        case 'status':
          return t(`common.status.${auditTrail[item]}`);
        case 'eventSubType':
          return t(`pages.auditTrail.eventSubTypes.${auditTrail[item]}`);
        case 'uemSystem':
          return t(`pages.uemOnboarding.uemTypes.${auditTrail[item]}`);
        case 'operationType':
          return t(`common.operationTypes.${auditTrail[item]}`);
        case 'bans':
          return auditTrail[item]?.length
            ? auditTrail[item]?.map((ban: BillingAccount) => ban.name).join(', ')
            : t('common.na');
        default:
          return auditTrail[item];
      }
    }
    return t('common.na');
  };

  const headers: Header[] = useMemo(
    () =>
      tableHeaders.map((item) => ({
        id: item,
        label: t(`pages.auditTrail.table.headers.${item}`),
        isSortable: false,
        sx: { minWidth: '12rem' },
      })),
    [t, tableHeaders],
  );

  const rows: Row[] = useMemo(
    () =>
      transactions?.map((auditTrail) => ({
        id: auditTrail.id,
        cells: tableHeaders.map((item) => ({
          value: `${renderCellValue(auditTrail, item as keyof AuditTrail)}`,
        })),
      })),
    [t, transactions, tableHeaders],
  );

  useEffect(() => {
    DashboardService.api.fetchLatestTransactionColumns().then((response) => {
      setTableHeaders(response.data.columns);
      fetchLatestTransactions();
    });
  }, []);

  return { rows, isLoading, headers };
}

export default useLatestTransactions;
