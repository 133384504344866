import { Autocomplete as MaterialAutocomplete, Box, createFilterOptions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '../Dropdown/DropdownModels';
import { InputBaseWrapper, InputLabelWrapper } from '../TextInput/TextInputStyle';
import { AutoCompleteOption } from './AutoCompleteStyle';

interface AutoCompleteProps {
  label: string;
  options: DropdownItem[];
  // On selected option
  onChange: (opt: DropdownItem) => void;
  // On keydown - possibly used for async calls
  onInputChange?: (val: string | undefined) => void;
  placeholder?: string;
  noOptionsText?: string;
  value?: DropdownItem;
  isLoading?: boolean;
}

function AutoComplete({
  label,
  options,
  placeholder,
  noOptionsText,
  onChange,
  onInputChange,
  value,
  isLoading = false,
}: AutoCompleteProps) {
  const [t] = useTranslation();
  const WAIT_INTERVAL = 500;
  let timerID: string | number | NodeJS.Timeout | undefined;

  /**
   * When the input changes, perform a debounce to throttle the fetch of new options
   * @param val
   */
  const inputChanged = (val: string) => {
    if (onInputChange) {
      clearTimeout(timerID);
      timerID = setTimeout(() => {
        onInputChange(val);
      }, WAIT_INTERVAL);
    }
  };

  /**
   * Defines the behaviour of the autocomplete filter
   * The filter is done by label + secondaryLabel
   */
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: DropdownItem) => `${option.label} ${option.secondaryLabel}`,
  });

  return (
    <Box
      sx={{
        '& .MuiAutocomplete-listbox': {
          padding: '0 !important',
        },
        '& .MuiPaper-root': {
          width: '100%',
          height: 'auto',
          overflowY: 'auto',
          padding: 0,
        },
      }}
    >
      {label ? <InputLabelWrapper shrink>{label}</InputLabelWrapper> : null}
      <MaterialAutocomplete
        onChange={(e, opt) => {
          onChange(opt as DropdownItem);
        }}
        onInputChange={(e, val) => inputChanged(val)}
        value={value}
        options={options}
        loading={isLoading}
        filterOptions={filterOptions}
        clearOnEscape
        clearOnBlur
        loadingText={t('common.loading')}
        getOptionLabel={(option) => `${option.label}` as string}
        noOptionsText={noOptionsText || t('common.noOptionsAvailable')}
        disablePortal
        renderOption={(props, option) => (
          <AutoCompleteOption {...props} key={option.key}>
            <span>{option.label}</span>
            {option.secondaryLabel ? (
              <span style={{ marginLeft: '0.5rem' }} className="secondaryLabel">
                {option.secondaryLabel}
              </span>
            ) : null}
          </AutoCompleteOption>
        )}
        renderInput={(params) => (
          <InputBaseWrapper
            ref={params.InputProps.ref}
            id={params.id}
            placeholder={placeholder}
            disabled={params.disabled}
            fullWidth={params.fullWidth}
            size={params.size}
            inputProps={params.inputProps}
          />
        )}
      />
    </Box>
  );
}

export default AutoComplete;
