import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Drawer from '../../../components/Drawer/Drawer';
import { reset } from '../../../stores/ImportStore';
import ImportErrorsStep from './ImportErrorsStep';
import ImportFileStep from './ImportFileStep';
import ImportSummaryStep from './ImportSummaryStep';

interface ImportDrawerProps {
  open: boolean;
  onClose: () => void;
}

function ImportDrawer({ open, onClose }: ImportDrawerProps) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const NUMBER_OF_STEPS = 3;
  const [progress, setProgress] = useState(100 / NUMBER_OF_STEPS);

  /**
   * Handle the close action
   */
  const handleClose = () => {
    dispatch(reset());
    setStep(1);
    onClose();
  };

  const [t] = useTranslation();

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Next step increment
   */
  const nextStep = (next?: number) => {
    if (next && Number(next)) setStep(next);
    else setStep((prevState) => prevState + 1);
  };

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === 1) {
      handleClose();
    } else if (step === 3) setStep(1);
    else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of the upgrade device journey
   */
  const renderSteps = () => {
    switch (step) {
      case 1:
        return <ImportFileStep nextStep={nextStep} onClose={handleClose} />;
      case 2:
        return <ImportErrorsStep nextStep={nextStep} onClose={handleClose} />;
      case 3:
        return <ImportSummaryStep onClose={handleClose} />;
      default:
        return null;
    }
  };

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={t('pages.import.importDrawer.title')}
      progress={progress}
    >
      <Box sx={{ paddingTop: '2rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default ImportDrawer;
